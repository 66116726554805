// import React from 'react'
// import PrimaryButton from './Buttons'
// import { Link } from 'react-router-dom'

// const PatientNavCont = () => {
//     return (
//         <nav className='dashboardNavCont'>
//             <h2>Dashboard Nav Cont</h2>
//             <PrimaryButton type="button">Dashboard</PrimaryButton>
//             <PrimaryButton type="button">My profile</PrimaryButton>
//             <PrimaryButton type="button">Upcoming events</PrimaryButton>
//             <PrimaryButton type="button"><Link to="/PatientListDown">Patient Information</Link></PrimaryButton>
//         </nav>
//     )
// }

// export default PatientNavCont

import React, { useEffect, useState } from 'react'
import { NavButton } from './Buttons'
import logo from '../Assets/Icons/Logo2.png'
import profilePhoto from '../Assets/Icons/blank-profile.webp'
import { Link } from 'react-router-dom'
import './Component Styles/PatientNavCont.css'
// import dashboardIcon from '../Assets/Icons/dashboardIcon.png'
// import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { LuLayoutDashboard } from "react-icons/lu";
// import myProfileIcon from '../Assets/Icons/myProfileIcon.png'
// import upcomingEventsIcon from '../Assets/Icons/upcomingEventsIcon.png'
// import patientInfoIcon from '../Assets/Icons/patientInfoIcon.png'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'


const NavIcon = styled.img`
	display: block;
	height: 20px;
	background-color: transparent;

	${(props) => props.active && `filter: invert(100%); mix-blend-mode: difference; `};

`
const PatientNavCont = () => {

	useEffect(() => {
		overflowReset();
		// console.log("i have been reset");
		
	}, []);
	const location = useLocation();

	const overflowReset = () => {
		const rootElement = document.documentElement;
		if (rootElement.style.overflow === 'hidden') {
			rootElement.style.overflow = 'auto';
		}
	};

	const isActive = (paths) => {
		return paths.includes(location.pathname);
	};
	// const isActive = (path) => {
	// 	return location.pathname === path;
	// };
	return (
		// <>


		<div className='dashboardNavCont'>
			<div className='dashboardNavContMobile'>
				<img src={logo} className='profileNavlogo' alt="Logo" />
				<img src={profilePhoto} className='profilePhoto' alt="ProfileImage" />
				<p className='profileName'>Dr. Name Surname</p>
			</div>
			<div className='topNavCont'>
				<img src={logo} className='profileNavlogo' alt="Logo" />
				<div className='profileCont'>
					<img src={profilePhoto} className='profilePhoto' alt="ProfileImage" />
					<p className='profileName'>Dr. Name Surname</p>
				</div>
				<div className='navButtonCont'>
					{/* <PrimaryButton type="button">Dashboard</PrimaryButton>
				<PrimaryButton type="button">My profile</PrimaryButton>
				<PrimaryButton type="button">Upcoming events</PrimaryButton>
				<PrimaryButton type="button"><Link to="/PatientListDown">Patient Information</Link></PrimaryButton> */}
					<NavButton className='navButton' type="button" active={isActive('/patientDashboard')} to={'/patientDashboard'}>
						{/* <NavIcon src={dashboardIcon} className='navListIcon' alt="Dashboard Icon" /> */}
						{/* <LuLayoutDashboard className='navButtonIcon' /> */}
						<DashboardOutlinedIcon className='navButtonIcon' />
						<p style={{ width: '75%', fontSize: '13px', textAlign: 'left' }}>Dashboard</p>
					</NavButton>
					<NavButton className='navButton' type="button" active={isActive('/profile')} to="/profile">
						{/* <NavIcon src={myProfileIcon} className='navListIcon' alt="Dashboard Icon" /> */}
						<AccountCircleOutlinedIcon className='navButtonIcon' />
						<p style={{ width: '75%', fontSize: '13px', textAlign: 'left' }}>My profile</p>
					</NavButton>
					<NavButton className='navButton' type="button" active={isActive('/events')} to="/events">
						{/* <NavIcon src={upcomingEventsIcon} className='navListIcon' alt="Dashboard Icon" /> */}
						<CalendarMonthOutlinedIcon className='navButtonIcon' />
						<p style={{ width: '75%', fontSize: '13px', textAlign: 'left' }}>Upcoming events</p>
					</NavButton>
					<NavButton className='navButton' type="button" active={isActive(['/patientInformation', '/patientInformation/:patientId'])} to="/patientInformation">
						{/* <NavButton className='navButton' type="button" active={isActive()} to="/patientListDown"> */}
						{/* <NavIcon src={patientInfoIcon} className='navListIcon' alt="Dashboard Icon" active={isActive('/patientDashboard')}/> */}
						<PlagiarismOutlinedIcon className='navButtonIcon' />
						<p style={{ width: '75%', fontSize: '13px', textAlign: 'left' }}>Patient Information</p>
					</NavButton>
				</div>
			</div>
			<div className='bottomNavCont'>
				<NavButton type="button"><Link to="/">Log out</Link></NavButton>
			</div>
		</div>



		// {/* </> */}
	)
}

// const toggleDisplay = (display) => {
// 	const navCont = document.querySelector(display);


// 	if (navCont.style.left === 'calc(0% + 20px)') {
// 		navCont.style.left = '-100%';
// 	} else {
// 		navCont.style.left = 'calc(0% + 20px)';
// 	}
// }




const PatientTopNav = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const handleMenuClick = () => {
		setIsMenuOpen(!isMenuOpen);

		const rootElement = document.documentElement;
		const navCont = document.querySelector('.patientMobileNavCont');
		if (navCont.style.left === 'calc(0% + 20px)') {
			navCont.style.left = '-100%';
			rootElement.style.overflow = 'auto';
		} else {
			navCont.style.left = 'calc(0% + 20px)';
			rootElement.style.overflow = 'hidden';
			// confirm('Really?');
		}
	}
	return (
		<div className='patientMobileCont'>
			<div className='patientTopNavCont'>
				{isMenuOpen ? (
					<MenuRoundedIcon className='menuIcon' onClick={handleMenuClick} />
				) : (
					<CloseRoundedIcon className='menuIcon' onClick={handleMenuClick} />
				)}
				{/* <MenuRoundedIcon className='menuIcon' onClick={() => toggleDisplay('.patientMobileNavCont')} /> */}
				<img src={logo} className='profileNavlogoMobile' alt="Logo" />
			</div>
			<div className='patientMobileNavCont'>
				<PatientNavCont />
			</div>
		</div>
	)
}

export { PatientNavCont, PatientTopNav }