import React from 'react'
import "./SessionDetails.css"
import Dashboard from '../../Components/Dashboard'
import { NavButton } from '../../Components/Buttons'
import skipBackIcon from '../../Assets/Icons/skipBackIcon.png'
import skipForwardIcon from '../../Assets/Icons/skipForwardIcon.png'
import pauseIcon from '../../Assets/Icons/pauseIcon.png'
import sessionRecordingBar from '../../Assets/Icons/recordingBar.png'

const SessionDetails = () => {

	const notiContElements = [
		<NavButton className='sessionDetailsButton' w={"200px"} to={`./editDetails`} active={true}>Edit Details</NavButton>,
	]
	return (
		<Dashboard title="Session Details" notiContElements={notiContElements}>
			<div className='sessionDetailsMainCont'>
				<div className='sessionDetailsUpperCont'>
					<h3 className='sessionDetailsTitle'>Therapy Session - Individual</h3>
					<div className='sessionDetailsInfoCont'>
						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Session ID :</div>
							<div className='sessionDetailsInfoValue'>TH-1234</div>
						</div>
						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Date :</div>
							<div className='sessionDetailsInfoValue'>21 / July / 2024</div>
						</div>

						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Time :</div>
							<div className='sessionDetailsInfoValue'>9.30 am</div>
						</div>

						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Doctor :</div>
							<div className='sessionDetailsInfoValue'>Dr. Jane Smith</div>
						</div>

						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Patient :</div>
							<div className='sessionDetailsInfoValue'>John Doe</div>
						</div>

						<div className='sessionDetailsInfo'>
							<div className='sessionDetailsInfoTitle'>Status :</div>
							<div className='sessionDetailsInfoValue'>Completed</div>
						</div>


					</div>
				</div>
				<div className='sessionDetailsLowerCont'>
					<div className='sessionDetailsLowerLeftCont'>
						<div className='sessionTreatmentCont'>
							<h3 className='sessionDetailsTitleSm'>Treatment Details</h3>
						</div>
						<div className='sessionRecordingCont'>
							<h3 className='sessionDetailsTitleSm'>Session Recording</h3>
							<p className='sessionRecordingTime'>00<span>h</span> : 22<span>m</span> : 10<span>s</span></p>
							<img src={sessionRecordingBar} className='sessionRecordingBar' alt="Session Recording Bar" />
							<div className='sessionRecordingButtonCont'>
								<img src={skipBackIcon} className='sessionRecordingIcon' alt="Skip Back Icon" />
								<img src={pauseIcon} className='sessionRecordingIcon' alt="Skip Back Icon" />
								<img src={skipForwardIcon} className='sessionRecordingIcon' alt="Skip Back Icon" />
							</div>
						</div>
					</div>
					<div className='sessionDetailsNotesCont'>
						<h3 className='sessionDetailsTitleSm'>Notes</h3>
					</div>
				</div>
			</div>
		</Dashboard>
	)
}

export default SessionDetails