// import React from 'react'
// import PatientNavCont from '../../../Components/PatientNavCont'
// import backgroundImg from '../../../Assets/Images/signup-form-background.png'

// const PatientListdown = () => {
//   return (
//     <div className='dashboard'>
//             <img src={backgroundImg} className='dashboardBackground' alt="Dashboard Background"/>
//             <PatientNavCont/>
//             <div className='dashboardMainCont'>
//                 <h2 className='dashboardTitleCont'>Patient Information</h2>

//             </div>
//         </div>
//   )
// }

// export default PatientListdown

import React from 'react'
import Dashboard from '../../../Components/Dashboard'
import { useLocation } from 'react-router-dom'
import { IoSearch } from "react-icons/io5";
import "./PatientListdown.css"
import { SearchBar, TextInput } from '../../../Components/Inputs';
import { SearchButton } from '../../../Components/Buttons';
import BasicTable from '../../../Components/PatientInformationTable';
import PatientInformationTable2 from '../../../Components/PatientInformationTable2';

const PatientListdown = () => {
	const location = useLocation();
	return (
		<Dashboard title="Patients">
			<div className='patientListdownCont'>
				<div className='patientListdownSearchCont'>
					<div className='patientListdownSearchInput'>
						<IoSearch style={{ color: "#757575" }} />
						<SearchBar placeholder="Search..." />
						<SearchButton type="submit">Search</SearchButton>
					</div>
					<select name="cars" id="cars" className='patientListdownSortDropdown'>
						<option value="" disabled selected>Sort</option>
						<option value="volvo">Volvo</option>
						<option value="saab">Saab</option>
						<option value="opel">Opel</option>
						<option value="audi">Audi</option>
					</select>

				</div>
				<div className='patientListdownTableCont'>
					<div className='patientListdownTableContTitle'>
						<h2>Patient Information</h2>
						<p>23rd July 2024</p>
					</div>
					<PatientInformationTable2 />
				</div>
			</div>
		</Dashboard>
	)
}

export default PatientListdown