import React, { useState } from 'react'
import styled from 'styled-components'
import folderIcon from '../Assets/Icons/FolderIcon.svg'

import { IoSearch } from "react-icons/io5";

const TextInputComponent = styled.input`
    all: unset;
    height: 52px;
    width: 80%;
    max-width: 272px;
    border-radius: 10px;
    /* padding: 15px 0; */
    text-align: left;
    background-color: #ffffffd6;
    padding: 0 20px;
    font-size: 13px;
    color: #8D8D8D;
    border-color: #FFFFFF1A;
    margin-top: 10px ;

    &:focus {
        outline: solid 2px #0000003f;
        color: black;
    }

`
const ValueInputComponent = styled.input`

    all: unset;
    width: ${props => props.w ? props.w : '80%'};
    height: ${props => props.h ? props.h : '30px'};
    // max-width: 150px;
    // max-width: 272px;
    text-align: left;
    background-color: #ffffffd6;
    padding: 0 20px;
    font-size: 13px;
    font-family: 'Inter';
    color: #8D8D8D;
    color: black;
    border: 1px solid #C5C5C5;
    border-radius: 4px;

    &:focus {
        border: solid 2px #0000003f;
    }


    ${props => props.fSize === "lg" && `
        font-size: 20px;
        font-weight: 600;
        color: black;
    `}

`

const CheckBoxInputComponent = styled.input`
    margin-right: 5px;
    padding: 20px;
    width: 16px;
    height: 16px;
    border: 1px solid white;
`

const FileInputComponent = styled.div`
    position: relative;
    all: unset;
    height: 52px;
    width: 312px;
    border-radius: 10px;
    text-align: center;
    background-color: #ffffffd6;
    font-size: 13px;
    color: #8D8D8D;
    border-color: #FFFFFF1A;
    margin-top: 10px ;
`
const FileInput = styled.input`
    display: none;
`

const FileLabel = styled.label`
    position: relative;
    cursor: pointer;
    height: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
`

const FileName = styled.span`
    // color: #000000;
    color: #8D8D8D;
`


const TextInput = ({ name, id, placeholder, required }) => {
    return (
        <>
            <TextInputComponent type="text" name={name} id={id} placeholder={placeholder} required={required}></TextInputComponent>
        </>
    )
}
const ValueInput = ({ name, id, placeholder, required, type, w, h, fSize}) => {
    return (
        <>
            <ValueInputComponent w={w} h={h} fSize={fSize} type={type ? type : 'text'}name={name} id={id} placeholder={placeholder} required={required}></ValueInputComponent>
        </>
    )
}
// const DateInput = ({ name, id, placeholder, required }) => {
//     return (
//         <>
//             <ValueInputComponent type="text" name={name} id={id} placeholder={placeholder} required={required}></ValueInputComponent>
//         </>
//     )
// }
const CheckBoxInput = ({ name, id, label, required }) => {
    return (
        <>
            <CheckBoxInputComponent type="checkbox" name={name} id={id} required={required} />
            <label htmlFor={id}>{label}</label>
        </>
    )
}
const EmailInput = ({ name, id, placeholder, required }) => {
    return (
        <TextInputComponent type="email" name={name} id={id} placeholder={placeholder} required={required}></TextInputComponent>
    )
}
const PasswordInput = ({ name, id, placeholder, required }) => {
    return (
        <TextInputComponent type="password" name={name} id={id} placeholder={placeholder} required={required} ></TextInputComponent>
    )
}

const ImageInput = ({ name, id, placeholder, allowedTypes, required }) => {

    const [fileName, setFileName] = useState('');
    // const [error, setError] = useState(false);

    const handleFileChange = (e) => {
        if (!e.target.files[0]) {
            setFileName(placeholder);
            // setError(false);
            return;
        }else{
            const file = e.target.files[0];
            const allowed = allowedTypes.some(type => file.type.includes(type));
            if (!allowed) {
                // setError(true);
                setFileName('');
                return;
            }else{
                // setError(false);
                setFileName(file.name);
            }
        };
        
    };
    return (
        <FileInputComponent>
            <FileInput type="file" name={name} id={id} accept={allowedTypes ? allowedTypes.join(', ') : ''} onChange={handleFileChange} required={required} />
            <FileLabel htmlFor={id}>
                {/* {error && <div className='invalidFileType'><p>Please upload a file of type {allowedTypes.join(', ')}</p></div>} */}
                <FileName>{fileName || placeholder}</FileName>
                <img src={folderIcon} alt="Folder Icon" />
            </FileLabel>
        </FileInputComponent>
    )
}

const SearchBarComponent = styled.input`
    all: unset;
    height: 100%;
    width: 100%;
    // border: 1px solid black
    font-family: 'Inter';
    font-size: 12px;
    margin-left: 10px;

`

const SearchBar = ({ name, id, placeholder, required }) => {
    return (
        <SearchBarComponent 
        type="text" 
        name={name} 
        id={id} 
        placeholder={placeholder}
        
        required={required}>

        </SearchBarComponent>
    )
}


export { TextInput, ValueInput, CheckBoxInput, EmailInput, PasswordInput, ImageInput, SearchBar }