import React, { useState } from 'react'
// import PatientDashboard from '../PatientDashboard'
import { useParams } from 'react-router-dom';
import Dashboard from '../../../Components/Dashboard';
import './PatientInformation.css'

import facebookIcon from '../../../Assets/Icons/facebook.png';
import whatsappIcon from '../../../Assets/Icons/whatsapp.png';
import linkedInIcon from '../../../Assets/Icons/linkedin.png';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { NavButton } from '../../../Components/Buttons';


// const 
const PatientInformation = () => {

    const [activeComponent, setActiveComponent] = useState('patientInformation');
    const setActiveComponentFunction = (componentName) => {
        setActiveComponent(componentName);
        const patientNavItems = document.querySelectorAll('.patientInformationExtNavItem');
        patientNavItems.forEach(patientNavItem => {
            patientNavItem.style.color = 'black';
            patientNavItem.style.borderBottom = '2px solid transparent';
        })
        document.getElementsByClassName(`patientInformationExtNavItem ${componentName}`)[0].style.color = '#2565C5';
        document.getElementsByClassName(`patientInformationExtNavItem ${componentName}`)[0].style.borderBottom = '2px solid #2565C5';
    }

    // const activeComponent = () => {

    // }

    const { patientName } = useParams();
    return (
        <Dashboard title="Patient > Profile">
            <div className='patientInformationMainCont'>
                <div className='patientInformationCont topHalfBox'>
                    <div className='patientPhotoCont'>
                        <img src="https://via.placeholder.com/150" className='patientProfileImage' alt="Patient Profile" />
                    </div>
                    <div className='patientDetailsCont'>
                        <h2 className='patientName'>{patientName}</h2>
                        <ul className='patientDetailsList'>
                            {/* <li className='patientItem'><span className='patientItemTitle'>Name</span>: {patientName}</li> */}
                            <li className='patientItem'><span className='patientItemTitle'>Phone</span>: 123456789</li>
                            <li className='patientItem'><span className='patientItemTitle'>Email</span>: kalindugunathilake123@gmail.com</li>
                            <li className='patientItem'><span className='patientItemTitle'>Address</span>: Baya Una Road, Andayata</li>
                        </ul>
                        <div className='patientSocialLinksCont'>
                            <img src={linkedInIcon} className='patientSocialLinkIcon' alt="Facebook Icon" />
                            <img src={whatsappIcon} className='patientSocialLinkIcon' alt="Facebook Icon" />
                            <img src={facebookIcon} className='patientSocialLinkIcon' alt="Facebook Icon" />
                        </div>
                    </div>
                </div>
                <div className='patientInformationMisc topHalfBox'>

                </div>
                <div className=' patientInformationExtCont bottomBox'>
                    <ul className='patientInformationExtNav'>
                        <li className='patientInformationExtNavItem patientInformation' onClick={() => { setActiveComponentFunction('patientInformation'); }}>Patient Information</li>
                        <li className='patientInformationExtNavItem sessions' onClick={() => { setActiveComponentFunction('sessions') }}>Sessions</li>
                        <li className='patientInformationExtNavItem medicalRecords' onClick={() => { setActiveComponentFunction('medicalRecords') }}>Medical Records</li>
                    </ul>
                    <div className='patientInformationExtContent'>
                        {activeComponent === 'patientInformation' && patientInformation()}
                        {activeComponent === 'sessions' && sessionInformation()}
                        {activeComponent === 'medicalRecords' && medicalRecordsInformation()}
                    </div>
                </div>
            </div>

        </Dashboard>
    )
}

export default PatientInformation


const patientInformation = () => {
    return (
        <div className='patientInformationCont'>
            <div className='patientInformationTitleCont'>
                <h2 className='patientInformationTitle'>Patient Information</h2>
                <button className='patientInformationEditButton'>
                    <EditOutlinedIcon style={{ fontSize: '18px' }} />
                    Edit
                </button>
            </div>
            <div className='patientInformationListCont'>
                <h4 className='patientInformationListTitle'>Basic Information</h4>

                <ul className='patientInformationList'>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Name</span>
                        <span className='patientInformationListItemValue'>Kalindu Gunathilake</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Gender</span>
                        <span className='patientInformationListItemValue'>Male</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Date of Birth</span>
                        <span className='patientInformationListItemValue'>09 / 09 / 1999</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Email</span>
                        <span className='patientInformationListItemValue'>patient1@email.com</span>
                    </li>
                </ul>

            </div>
            <div className='patientInformationListCont'>
                <h4 className='patientInformationListTitle'>Address Information</h4>

                <ul className='patientInformationList'>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Street</span>
                        <span className='patientInformationListItemValue'>No. 99, Baya Una Road</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>City</span>
                        <span className='patientInformationListItemValue'>Andayata</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>District / State</span>
                        <span className='patientInformationListItemValue'>Colombo</span>
                    </li>
                    <li className='patientInformationListItem'>
                        <span className='patientInformationListItemTitle'>Country</span>
                        <span className='patientInformationListItemValue'>Sri Lanka</span>
                    </li>
                </ul>
            </div>

        </div>
    )
}
const sessionInformation = () => {
    return (
        <div className='patientInformationCont'>
            <div className='patientInformationTitleCont'>
                <h2 className='patientInformationTitle'>Session Information</h2>

            </div>
            <NavButton className='patientInformationSessionButton' to='./createSession' active={true}>Create Session</NavButton>
        </div>
    )
}
const medicalRecordsInformation = () => {
    return (
        <div className='patientInformationCont'>
            <div className='patientInformationTitleCont'>
                <h2 className='patientInformationTitle'>Medical Records Information</h2>
            </div>
        </div>
    )
}   