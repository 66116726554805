import React, { useEffect, useState } from 'react'
import './HomePage.css'
import headerImg from '../../Assets/Images/header-img.jpg'
// import logoWhite from '../../Assets/Icons/LogoWhite.png'
import logoWhite from '../../Assets/Icons/Logo.svg'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import homeIntroCardIconConfidentiality from '../../Assets/Icons/home-intro-card-icon-confidentiality.png'
// import homeIntroCardIconAccessibility from '../../Assets/Icons/home-intro-card-icon-accessibility.png'
// import homeIntroCardIconEmpowerment from '../../Assets/Icons/home-intro-card-icon-empowerment.png'
import homeIntroImg from '../../Assets/Images/homeIntro.jpg'
import arrowRightIcon from '../../Assets/Icons/arrow-right.png'
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import homeServicesImg from '../../Assets/Images/blob-scene-haikei new(1).svg'
import sessionManagementIcon from '../../Assets/Icons/calendarIcon.svg'
import secureNoteIcon from '../../Assets/Icons/docTextIcon.svg'
import personalizedTreatmentIcon from '../../Assets/Icons/sparkles.svg'
import voiceRecordingIcon from '../../Assets/Icons/speaker.wave.2.fill - L.svg'

import despressionImg from '../../Assets/Images/homeDepressionImg.png'
import angerIssuesImg from '../../Assets/Images/homeAngerIssuesImg.jpeg'
import stressImg from '../../Assets/Images/homeStressImg.png'

import homePricingBackground from '../../Assets/Images/circle-scatter-haikei (1)-cropped.svg'

import heartFillSVG from '../../Assets/Icons/heart.fill.svg'
import { useMediaQuery } from 'react-responsive';
import { HashRouter, Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';



const MobileNav = () => {

	useEffect(() => {
		Aos.init();
	}, [])

	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const handleMenuClick = () => {
		setIsMenuOpen(!isMenuOpen);
		const navCont = document.querySelector('.homeNavbar');
		const navList = document.querySelector('.homeNavbarListCont');
		const rootElement = document.documentElement;

		if (isMenuOpen) {
			console.log('open');
			navCont.style.height = 'calc(100dvh - 30px)';
			navList.style.height = 'calc(100dvh - 120px)';
			rootElement.style.overflow = 'hidden';
			Aos.refresh();
		} else {
			console.log('close');
			navCont.style.height = '70px';
			rootElement.style.overflow = 'auto';

		}
	}

	const navLink = document.querySelectorAll('.homeNavbarListItem');
	navLink.forEach(link => link.onclick = handleMenuClick);

	return (
		<nav className='homeNavbar'>
			<div className='homeNavbarCont'>
				<img className='homeNavbarLogo' src={logoWhite} alt="logo" />
				{/* <MenuRoundedIcon className='homeNavbarIcon' onclick={handleMenuClick} /> */}
				{isMenuOpen ? (
					<MenuRoundedIcon className='homeNavbarIcon' onClick={handleMenuClick} />
				) : (
					<CloseRoundedIcon className='homeNavbarIcon' onClick={handleMenuClick} />
				)}
			</div>
			<div className='homeNavbarListCont'>
				<ul className='homeNavbarList'>
					{/* <li className='homeNavbarListItem'><Link to='/login'>Home</Link></li> */}
					<li className='homeNavbarListItem' data-aos="fade-right" >Home</li>
					<li className='homeNavbarListItem' data-aos="fade-right" >Services</li>
					<li className='homeNavbarListItem' data-aos="fade-right" >Resources</li>
					<li className='homeNavbarListItem' data-aos="fade-right" >Pricing</li>
					<li className='homeNavbarListItem' data-aos="fade-right" >Contact</li>
				</ul>
				<div className='homeNavbarButtonCont'>
					<button className='homeSignupButton'><Link to='/signup' style={{ textDecoration: 'none', color: '#ffffff' }}>Sign in</Link></button>
					<button className='homeLoginButton'><Link to='/login' style={{ textDecoration: 'none', color: '#517d91' }}>Login</Link></button>
				</div>
			</div>

		</nav>
	)
}

const DesktopNav = () => {
	return (
		<nav className='homeNavbar'>
			<img className='homeNavbarLogo' src={logoWhite} alt="logo" />
			<ul className='homeNavbarList'>
				{/* <li className='homeNavbarListItem'><a href='#home'>Home</a></li> */}
				<li className='homeNavbarListItem'><Link to='#home'>Home</Link></li>
				<li className='homeNavbarListItem'><Link to='/#services'>Services</Link></li>
				<li className='homeNavbarListItem'>Resources</li>
				<li className='homeNavbarListItem'>Pricing</li>
				<li className='homeNavbarListItem'>Contact</li>
			</ul>
			<button className='homeSignupButton'><Link to='/signup' style={{ textDecoration: 'none', color: '#ffffff' }}>Sign in</Link></button>
		</nav>
	)
}


const HomePage = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

	useEffect(() => {
		overflowReset();
		// console.log("i have been reset");
		// Aos.init({ duration: 700 });

		mobileAnimations();
		
	}, []);

	const overflowReset = () => {
		const rootElement = document.documentElement;
		if (rootElement.style.overflow === 'hidden') {
			rootElement.style.overflow = 'auto';
		}
	};

	const mobileAnimations = () => {
		const homeIntroCard = document.querySelectorAll('.homeIntroCard');
		const homeServicesCaptionTitle = document.querySelectorAll('.homeServicesCaptionTitle');
		const homeIssuesCard = document.querySelectorAll('.homeIssuesCard');
		if (isMobile) {
			homeIntroCard.forEach(container => container.setAttribute('data-aos', 'fade-up'));
			homeServicesCaptionTitle[0].setAttribute('data-aos', 'zoom-in');
		} else {
			homeIntroCard.forEach((container, index) => {
				container.setAttribute('data-aos', 'fade-up')
				container.setAttribute('data-aos-delay', `${index * 100}`);
			});
			homeServicesCaptionTitle[0].setAttribute('data-aos', 'fade-right');
			homeServicesCaptionTitle[0].setAttribute('data-aos-anchor-placement', 'top-bottom');

			homeIssuesCard.forEach((container,index) => container.setAttribute('data-aos-delay', `${index * 100}`));
		}
	}

	return (
		// <HashRouter>
		<div className='homePage'>
			{/* <h1>Homepage</h1> */}
			{/* <nav className='homeNavbar'>
				<img className='homeNavbarLogo' src={logoWhite} alt="logo" />
				<ul className='homeNavbarList'>
					<li className='homeNavbarListItem'>Home</li>
					<li className='homeNavbarListItem'>Services</li>
					<li className='homeNavbarListItem'>Resources</li>
					<li className='homeNavbarListItem'>Pricing</li>
					<li className='homeNavbarListItem'>Contact</li>
				</ul>
				<button className='homeSignupButton'>Sign in</button>
			</nav> */}
			{isMobile ? MobileNav() : DesktopNav()}
			<header className='homeHeader' id='home'>
				<img className='headerImg' src={headerImg} alt="header image" />
				<div className='headerFilter'></div>
				<div className='headerTextCont'  >
					<h2 className='headerTitle' data-aos="fade-up" data-aos-duration="1000" >Making <br />Mental Wellness <br />Easy and Accessible</h2>
					<p className='headerCaption' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">We are committed to providing you with the best medical and
						healthcare services to help you live healthier and happier.</p>
				</div>
			</header>
			<div className='homeIntroCont'>
				<div className='homeIntroImgCont'>
					<img className='homeIntroImg' src={homeIntroImg} alt="IntroImage" />
					<button className='homeIntroButton'>Start Now
						<EastRoundedIcon style={{ fontSize: '19px', marginLeft: '20px' }} />
					</button>
				</div>
				<div className='homeIntroTextCont'>
					<h2 className='homeIntroTitle'>You deserve to be<br /><span>Mentally Healthy</span></h2>
					<p className='homeIntroCaption'>Our platform is dedicated to ensuring that every individual has the tools and support they need to maintain mental well-being. With a focus on accessibility, confidentiality, and community, we provide a comprehensive solution to help you manage your therapy sessions with ease and confidence.</p>
					{/* <button className='homeIntroGetStartedButton'>
						Get Started
						<img src={arrowRightIcon} alt="Arrow" />
					</button> */}
					<button className='btn41-43 btn-41'>
						Get Started
						<EastRoundedIcon style={{ fontSize: '19px', marginLeft: '20px' }} />
					</button>
					<div className='homeIntroCardsCont'>
						<div className='homeIntroCard'>
							<div className='homeIntroCardTitleCont'>
								<h3 className='homeIntroCardTitle'>Confidentiality</h3>
								{/* <img className='homeIntroCardIcon' src={homeIntroCardIconConfidentiality} alt="Icon" /> */}
							</div>
							<p className='homeIntroCardCaption'>Your privacy is our priority. We protect your information with the highest level of security.</p>
						</div>
						<div className='homeIntroCard' >
							<div className='homeIntroCardTitleCont'>
								<h3 className='homeIntroCardTitle'>Accessibility</h3>
								{/* <img className='homeIntroCardIcon' src={homeIntroCardIconAccessibility} alt="Icon" /> */}
							</div>
							<p className='homeIntroCardCaption'>Easily manage your therapy anytime, anywhere, on any device.</p>
						</div>
						<div className='homeIntroCard' >
							<div className='homeIntroCardTitleCont'>
								<h3 className='homeIntroCardTitle'>Empowerment</h3>
								{/* <img className='homeIntroCardIcon' src={homeIntroCardIconEmpowerment} alt="Icon" /> */}
							</div>
							<p className='homeIntroCardCaption'>Tools that put you in control of your mental wellness journey.</p>
						</div>
					</div>
				</div>
			</div>
			<div className='homeServicesCont' id='services'>
				<img className='homeServicesImg' src={homeServicesImg} alt="backgroundImage" />
				<div className='homeServicesTextCont'>
					<div className='homeSectionBadge'>#Services</div>
					<div className='homeServicesTitleCont'>
						<h2 className='homeServicesCaptionTitle'>Manage Everything,<br />All in One Place</h2>
						<p className='homeServicesCaption' data-aos="fade-right" data-aos-duration="600">Discover a range of easy-to-use tools created by experts to help you manage your therapy sessions. From scheduling to keeping notes, everything you need is here, all in one place. Start simplifying your mental wellness journey today.</p>
					</div>
				</div>
				<div className='homeServicesFeaturesCont'>
					<h3 className='homeServicesTitle'>Core Features for Seamless Therapy Management</h3>
					<ul className='homeServicesList'>
						<li className='homeServicesListItem' >
							<div className='homeServicesListItemIconCont'><img className='homeServicesListItemIcon' src={sessionManagementIcon} /></div>
							<p data-aos="fade-left" data-aos-duration="600" data-aos-anchor-placement="top-bottom">Session Management</p></li>
							{/* <p >Session Management</p></li> */}
						<li className='homeServicesListItem'>
							<div className='homeServicesListItemIconCont'><img className='homeServicesListItemIcon' src={secureNoteIcon} /></div>
							<p data-aos="fade-left" data-aos-duration="600" data-aos-delay="100" data-aos-anchor-placement="top-bottom">Secure Note Keeping</p></li>
							{/* <p >Secure Note Keeping</p></li> */}
						<li className='homeServicesListItem'>
							<div className='homeServicesListItemIconCont'><img className='homeServicesListItemIcon' src={personalizedTreatmentIcon} /></div>
							<p data-aos="fade-left" data-aos-duration="600" data-aos-delay="200" data-aos-anchor-placement="top-bottom">Personalized Treatment Plans</p></li>
							{/* <p >Personalized Treatment Plans</p></li> */}
						<li className='homeServicesListItem'>
							<div className='homeServicesListItemIconCont'><img className='homeServicesListItemIcon' src={voiceRecordingIcon} /></div>
							<p data-aos="fade-left" data-aos-duration="600" data-aos-delay="300" data-aos-anchor-placement="top-bottom">Voice Recording Integration</p></li>
							{/* <p >Voice Recording Integration</p></li> */}
					</ul>
				</div>
			</div>

			<div className='homeIssuesCont'>
				<h3 className='homeIssuesTitle'>Common Mental Health<br />Issues We Address</h3>
				<div className='homeIssuesCardsCont'>
					<div className='homeIssuesCard' data-aos="fade-up">
						<img className='homeIssuesCardImg' src={despressionImg} alt="despressionImg" />
						<p className='homeIssuesCardCaption'>Depression</p>
					</div>
					<div className='homeIssuesCard' data-aos="fade-up">
						<img className='homeIssuesCardImg' src={angerIssuesImg} alt="despressionImg" />
						<p className='homeIssuesCardCaption'>Anger Issues</p>
					</div>
					<div className='homeIssuesCard' data-aos="fade-up">
						<img className='homeIssuesCardImg' src={stressImg} alt="despressionImg" />
						<p className='homeIssuesCardCaption'>Stress</p>
					</div>
				</div>
			</div>

			<div className='homePricingCont'>
				<img className='homePricingBackground' src={homePricingBackground} alt="backgroundImage" />
				<h3 className='homePricingTitle'>Ready to get started?</h3>
				<p className='homePricingCaption'>Choose a plan that works best for you.</p>
				<div className='homePricingButtonsCont'>
					<button className='homePricingSignupButton filled' data-aos="flip-left">
						Sign Up
						<EastRoundedIcon />
					</button>
					<button className='homePricingViewButton' data-aos="flip-left" data-aos-delay="100">
						View Pricing
						<EastRoundedIcon />
					</button>
				</div>
			</div>

			<footer className='homeFooter'>
				<hr className='homeFooterLine' />
				<div className='homeFooterMidCont'>
					{/* <div className='homeFooterLogoCont'><img className='homeFooterLogo' src={logoWhite} alt="Logo" /></div> */}

					{/* <img className='homeFooterLogo' src={logoWhite} alt="Logo" /> */}
					<ul className='homeFooterLinksCont'>
						<li className='homeFooterLink' data-aos="fade-right" data-aos-anchor-placement="top-bottom">Home</li>
						<li className='homeFooterLink' data-aos="fade-right" data-aos-delay="50" data-aos-anchor-placement="top-bottom">About</li>
						<li className='homeFooterLink' data-aos="fade-right" data-aos-delay="100" data-aos-anchor-placement="top-bottom">Services</li>
						<li className='homeFooterLink' data-aos="fade-right" data-aos-delay="150" data-aos-anchor-placement="top-bottom">Pricing</li>
						<li className='homeFooterLink' data-aos="fade-right" data-aos-delay="200" data-aos-anchor-placement="top-bottom">Contact</li>
					</ul>
					<div className='homeFooterLogoCont'><img className='homeFooterLogo' src={logoWhite} alt="Logo" /></div>

				</div>
				<div className='homeFooterCaptionCont'>
					<p className='homeFooterCaption'>Copyright © 2024. All rights reserved. Made with <img src={heartFillSVG} alt="heart" /> by <span>24Streets</span> </p>
				</div>
			</footer>
		</div>
		// </HashRouter>
	)
}

export default HomePage