// import React from 'react'
// import './Dashboard.css'
// import PatientListdown from './PatientInformation/PatientListdown'
// import PrimaryButton from '../../Components/Buttons'
// import { Link } from 'react-router-dom'
// import PatientNavCont from '../../Components/PatientNavCont'
// import backgroundImg from '../../Assets/Images/signup-form-background.png'

// const Dashboard = () => {
//     return (
//         <div className='dashboard'>
//              <img src={backgroundImg} className='dashboardBackground' alt="Dashboard Background"/>
//             <PatientNavCont />
//             <div className='dashboardMainCont'>
//                 <h2 className='dashboardTitle'>Patient Dashboard</h2>

//             </div>
//         </div>
//     )
// }

// export default Dashboard


import React from 'react'
import './Component Styles/Dashboard.css'
import backgroundImg from '../Assets/Images/signup-form-background.png'
import { PatientNavCont, PatientTopNav } from './PatientNavCont'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useMediaQuery } from 'react-responsive';

const Dashboard = ({ children, title, notiContElements }) => {

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div className='dashboard'>
            <img src={backgroundImg} className='dashboardBackground' alt="Dashboard Background" />

            {isMobile ? <PatientTopNav /> : null}
            <div className='dashboardMainCont'>

                {isMobile ? null : <PatientNavCont />}

                <div className='dashboardCont'>
                    <div className='dashboardTitleCont'>
                        <h2>{title}</h2>
                        <div className='dashboardNotiCont'>
                            {notiContElements}
                            <div className='dashboardDate'>23rd July 2024</div>
                            <div className='dashboardIconCont'>
                                <NotificationsNoneOutlinedIcon style={{ fontSize: '20px' }} />
                                <MailOutlineRoundedIcon style={{ fontSize: '20px' }} />
                                <SettingsOutlinedIcon style={{ fontSize: '20px' }} />
                            </div>
                        </div>
                    </div>
                    <div className='dashboardComponentCont'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard