import React from 'react'
import { Link } from 'react-router-dom';
// import './Buttons.css'
import styled from 'styled-components'


const ButtonComponent = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 312px;
    height: 52px;
    background-color: #FF5858;
    color: #FFFFFF;
    font-size: 15px;
    border-radius: 10px;
    margin-top: 20px;
    border: 1px solid #FFFFFF4D;
    transition: all 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
        margin-top: 18px;
        margin-bottom: 2px;
        box-shadow: 0 2px 5px 0px #0000003f;
    }

    &:active {
        margin-top: 20px;
        margin-bottom: 0px;
        box-shadow: none;
    }
`;

// const moveToCont = async ({onclickvalue}) => {
//   const containers = await document.querySelectorAll('.Cont1, .Cont2, .Cont3, .Cont4')
//   // containers.forEach(c => c.style.display = 'none')
//   // containers[onclickvalue[1]].style.display = 'flex'
//   // console.log(onclickvalue[1])
//   console.log(containers)
//   if (containers.length > 0 ){
//     containers.forEach(c=> console.log(c))
//   }
// }
// const toggleDisplay = () => {
// 	// const containers = document.querySelectorAll('.signupInput');
// 	// containers.forEach(c => c.style.display = 'none');
// 	// document.querySelector(`.signupInput.${display}`).style.display = 'flex';
// 	console.log("helloooo")
// }

const toggleDisplay = (display) => {
  const containers = document.querySelectorAll('.Cont1, .Cont2, .Cont3, .Cont4');
  containers.forEach(c => c.style.display = 'none');
  document.querySelector(`${display}`).style.display = 'flex';
}
// const toggleDisplay = (display) => {
//   console.log('hellooo')
// }
const PrimaryButton = ({ children, className, type, id, onClick }) => {
  return (
    <ButtonComponent
      className={className}
      type={type}
      id={id}
      onClick={onClick}
    >
      {children}
    </ButtonComponent>

  )
}


const NavButtonComponent = styled.button`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
    color: black;
    cursor: pointer;
    ${props => props.active && `
        background-color: #5DC9F7;
        color: white;
    `}
    width: 80%;
    height: 80%;
    padding : 10px 0;
    border-radius: 4px;
    font-family: "Montserrat", sans-serif;
    // font-size: 20px;
    // color: white;
    // fill: white;

      *{
        ${props => props.active && `
          background-color: #5DC9F7;
          color: white;
        `}
        text-decoration: none;
      }
`;


const StyledLink = styled(Link)`

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  color: black;
  cursor: pointer;
  ${props => props.active && `
      background-color: #5DC9F7;
      color: white;
  `}
  // width: 80%;
  width: ${props => props.w ? props.w : '80%'};
  height: 80%;
  padding : 10px 0;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;


  text-decoration: none;
  // width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // cursor: default;
  // padding: 13px 0;
`

const NavButton = ({ children, className, type, id, onClick, active, to, w }) => {
  return (
    // <NavButtonComponent
    <StyledLink
      className={className}
      type={type}
      id={id}
      onClick={onClick}
      active={active}
      to={to}
      w={w}
    >
      {/* <StyledLink to={to}> */}
      {children}
      {/* </StyledLink> */}
      {/* </NavButtonComponent> */}
    </StyledLink>
  )

}

const SearchButtonComponent = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    cursor: pointer;
    border: 2px solid #9E9E9E;
    border-radius: 40px;
    margin-right: 4.5px;
    width: 20%;
    min-width: 50px;
    height: 80%;
    padding : 10px 0;
    font-family: "Inter", sans-serif;
    // font-size: 12px;
    font-size: calc(min(13px, 6px + 0.5vw));
`

const SearchButton = ({ children, className, type, id, onClick, active }) => {
  return (
    <SearchButtonComponent
      className={className}
      type={type}
      id={id}
      onClick={onClick}
      active={active}
    >
      {children}
    </SearchButtonComponent>
  )
}

// export default {PrimaryButton , NavButton}

export { toggleDisplay, PrimaryButton, NavButton, SearchButton }



// Example usage:
// <primaryButton className='loginButton' onClick={myFunction} >Sign up</primaryButton>
