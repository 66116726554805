import React from 'react'
import signupImage from '../../Assets/Images/signup-image.jpg'
import signupBluryBackground from '../../Assets/Images/signup-form-background.png'
import signupLogo from '../../Assets/Images/logo.png'
import backIcon from '../../Assets/Icons/BackIcon.svg'
import {PrimaryButton} from '../../Components/Buttons'
import { toggleDisplay } from '../../Components/Buttons'
import { Link } from 'react-router-dom'
import { EmailInput, PasswordInput, TextInput, ImageInput } from '../../Components/Inputs'
import './Signup.css'


const formValidation = () => {
	const requiredInputs = Array.from(document.querySelectorAll('input[required]'));
	const allRequiredInputsHaveValue = requiredInputs.every(input => input.value !== '');

	if (!allRequiredInputsHaveValue) {
		const firstEmptyInput = requiredInputs.find(input => input.value === '');
		if(firstEmptyInput){
			const emptyInputs = requiredInputs.filter(input => input.value === '');
			emptyInputs.forEach(emptyInput => {
				toggleDisplay(`.Cont${Number(emptyInput.name.split('Cont')[1]) + 1}`);
			})
		}
		return false;
	}

	// const allInputsHaveValue = Array.from(cont1Inputs).every(input => input.value !== '');
	return true
}
const Signup = () => {
	return (
		<div className='signupMainCont'>
			<img src={signupBluryBackground} className='signupBluryBackground' alt="Signup form background" />
			<img src={signupImage} className='signupImage' alt="Login Image" />
			<div className='signupFormMainCont'>
				{/* <div  className='signupFormCont'> */}
				<form action="" className='signupFormCont'>
					<img src={signupLogo} className='signupLogo' />
					<h2 className='signupTitle'>Welcome to Mind Wellness Lab's</h2>
					<p className='signupSubTitle'>Let's create your free account</p>
					{/* <button type="button" className='signupButton' onClick={() =>toggleDisplay()}>Click me</button> */}

					<div className='signupInput Cont1'>

						<TextInput className='Cont1Name' placeholder='Name' required />
						<EmailInput className='Cont1Email' placeholder='Email' required />
						<PasswordInput className='Cont1Password' placeholder='Password' required />
						<PrimaryButton type="button" onClick={() => {
							const inputs = document.querySelectorAll('.signupInput.Cont1 input');
							const allInputsHaveValue = Array.from(inputs).every(input => input.value !== '');
							if (allInputsHaveValue) toggleDisplay('.Cont2');
						}}>
							Next
						</PrimaryButton>
						{/* <PrimaryButton type="button" onClick={() => {
							const inputs = document.querySelectorAll('.Cont1Name, .Cont1Email, .Cont1Password');
							const allInputsHaveValue = inputs.every(input => input.value !== '');
							if(allInputsHaveValue) toggleDisplay('.Cont2');
						}}>
							Next
						</PrimaryButton> */}
					</div>
					<div className='signupInput Cont2' >
						<button type='button' className='signupBackButton' onClick={() => toggleDisplay('.Cont1')}>
							<img src={backIcon} className='signupBackIcon' alt="Back Icon" />
						</button>
						<TextInput placeholder='Profession' required />
						<TextInput placeholder='Address' required />
						<TextInput placeholder='Country' required />
						{/* <PrimaryButton type="button" onClick={() => toggleDisplay('.Cont3')}>Next</PrimaryButton> */}
						<PrimaryButton type="button" onClick={() => {
							const inputs = document.querySelectorAll('.signupInput.Cont2 input');
							const allInputsHaveValue = Array.from(inputs).every(input => input.value !== '');
							if (allInputsHaveValue) toggleDisplay('.Cont3');
						}}>
							Next
						</PrimaryButton>
					</div>
					<div className='signupInput Cont3' >
						<button type='button' className='signupBackButton' onClick={() => toggleDisplay('.Cont2')}>
							<img src={backIcon} className='signupBackIcon' alt="Back Icon" />
						</button>
						<p className='signupInputTitle'>National ID</p>
						<ImageInput placeholder='Upload Image' id="NICImageInput" allowedTypes={['image/png', 'image/jpeg', 'image/jpg']} required />
						<p className='signupInputTitle'>Professional ID</p>
						<ImageInput placeholder='Upload Image' id="ProfessionalIDInput" allowedTypes={['image/png', 'image/jpeg', 'image/jpg']} required />
						{/* <PrimaryButton type="button" onClick={() => toggleDisplay('.Cont4')}>Next</PrimaryButton> */}
						<PrimaryButton type="button" onClick={() => {
							const inputs = document.querySelectorAll('.signupInput.Cont3 input');
							const allInputsHaveValue = Array.from(inputs).every(input => input.value !== '');
							if (allInputsHaveValue) toggleDisplay('.Cont4');
						}}>
							Next
						</PrimaryButton>
					</div>
					<div className='signupInput Cont4' >
						<button type='button' className='signupBackButton' onClick={() => toggleDisplay('.Cont3')}>
							<img src={backIcon} className='signupBackIcon' alt="Back Icon" />
						</button>
						<PasswordInput placeholder='Enter Password' required />
						<PasswordInput placeholder='Re-Enter Password' required />
						<PrimaryButton type="button" onClick={() => console.log(formValidation())}>Signup</PrimaryButton>
					</div>



					<p className='signupAlreadyHaveAccount'>Already have an account? <span><Link to="/login">Log in</Link></span></p>
				</form>
			</div>
		</div>
	)
}

export default Signup
