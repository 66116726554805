import React from 'react'
import './Login.css'
import loginImage from '../../Assets/Images/login-image.jpg'
import loginBluryBackground from '../../Assets/Images/login-form-background.png'
import loginLogo from '../../Assets/Images/logo.png'
import {PrimaryButton} from '../../Components/Buttons'
// import { Link } from 'react-router-dom'
import { EmailInput, PasswordInput, CheckBoxInput } from '../../Components/Inputs'
import { Link } from 'react-router-dom'

const Login = () => {
	return (

		<div className='loginMainCont'>
			<img src={loginBluryBackground} className='loginBluryBackground' alt="Login Form Background" />
			<div className='loginImageCont'>
				<img src={loginImage} className='loginImage' alt="Login Image" />
			</div>
			<div className='loginFormMainCont'>
				<form action="" className='loginFormCont'>
					<img src={loginLogo} className='loginLogo' />
					<h2 className='loginTitle'>Welcome Back!</h2>
					<p className='loginSubTitle'>Login back to your account</p>
					<EmailInput placeholder='Please enter your email' required/>
					<PasswordInput placeholder='Please enter your password' required />
					<div className='loginRememberForgotCont'>
						<div className='loginRememberMeCont'>
							<CheckBoxInput name='rememberMe' id='rememberMe' className='loginCheckbox' label='Remember Me?' />
						</div>
						<div className='loginForgotPasswordCont'>
							<Link to="/">Forgot Password?</Link>
						</div>
					</div>
					<PrimaryButton type="submit"><Link to="/patientDashboard">Log In</Link></PrimaryButton>
					<p className='loginDontHaveAccount'>Don't have an account? <span><Link to="/signup">Sign up</Link></span></p>
				</form>
			</div>
		</div>

	)
}

export default Login