import React from 'react'
import Dashboard from '../../Components/Dashboard'

const PatientDashboard = () => {
  return (
    <Dashboard title="Dashboard">
        <div className='patientDashboardCont'>

        </div>
    </Dashboard>
  )
}

export default PatientDashboard