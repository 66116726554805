import React from 'react'
import Dashboard from '../../Components/Dashboard'
import './CreateSession.css'
import { ValueInput } from '../../Components/Inputs'
import recordingIndicatorIcon from '../../Assets/Icons/recordingIndicator.png'
import micIcon from '../../Assets/Icons/micIcon.png'
import playIcon from '../../Assets/Icons/playIcon.png'
import pauseIcon from '../../Assets/Icons/pauseIcon.png'
import checkmarkIcon from '../../Assets/Icons/checkmark.png'
import { NavButton } from '../../Components/Buttons'

const CreateSession = () => {

    const notiContElements = [
        // <button className='createSessionButton'></button>
        <NavButton className='createSessionButton' w={"200px"} to={`./sessionDetails/1`} active={true}>Create Session</NavButton>
    ]
    return (
        <Dashboard title={"Create Session"} notiContElements={notiContElements}>
            <div className='createSessionCont'>
                <div className='createSessionTopCont'>
                    <div className='createSessionInfoInputCont'>
                        <div className='createSessionInfoTitleCont'>
                            <p className='createSessionInfoTitle'>Session Name:</p>
                            <ValueInput type={"text"} w={"50%"} h={"30px"} fSize={"lg"} />
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Appointment ID :</p>
                            TH-1234
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Doctor :</p>
                            <ValueInput type={"text"} w={"50%"} h={"30px"} />
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Date :</p>
                            <ValueInput type={"date"} w={"50%"} h={"30px"} />
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Patient :</p>
                            <ValueInput type={"text"} w={"50%"} h={"30px"} />
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Time :</p>
                            <ValueInput type={"time"} w={"50%"} h={"30px"} />
                        </div>
                        <div className='createSessionInfoCont'>
                            <p className='createSessionInfoTitle'>Status :</p>
                            <ValueInput type={"text"} w={"50%"} h={"30px"} />
                        </div>
                    </div>
                    <div className='createSessionTopLeftCont'>
                        <div className='createSessionVoiceRecCont tophalfCont'>
                            <div className='createSessionVoiceRecUpperCont'>
                                <img className='recordingIndicator' src={recordingIndicatorIcon}></img>
                                <div className='createSessionVoiceRecText'>
                                    00<span>h</span> : 22<span>m</span> : 10<span>s</span>
                                    <p>This session is now being recorded</p>
                                </div>
                            </div>
                            <div className='createSessionVoiceRecLowerCont'>
                                <img src={micIcon} className='createSessionVoiceRecIcons'></img>
                                <div className='createSessionVoiceRecControls'>
                                    <img src={playIcon} className='createSessionVoiceRecIcons'></img>
                                    <img src={pauseIcon} className='createSessionVoiceRecIcons'></img>
                                    <img src={checkmarkIcon} className='createSessionVoiceRecIcons'></img>
                                </div>
                            </div>
                        </div>
                        <div className='tophalfCont'>

                        </div>
                    </div>
                </div>
                <div className='createSessionBottomCont'>
                    <div className='bottomHalfCont'>
                        <h2 className='createSessionNotesTitle'>Additional Notes</h2>
                    </div>
                    <div className='bottomHalfCont'>
                        <h2 className='createSessionNotesTitle'>Treatment Details</h2>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}

export default CreateSession