// import logo from './logo.svg';
import './App.css';
// import Home from './Pages/Home';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Signup from './Pages/Signup/Signup';
import Login from './Pages/Login/Login';
import PatientListdown from './Pages/Patient Dashboard/PatientListdown/PatientListdown';
import PatientDashboard from './Pages/Patient Dashboard/PatientDashboard';
import PatientInformation from './Pages/Patient Dashboard/PatientInformation/PatientInformation';
import CreateSession from './Pages/Session/CreateSession';
import SessionDetails from './Pages/Session/SessionDetails';
import HomePage from './Pages/HomePage/HomePage.js';
import Aos from 'aos';
import { useEffect } from 'react';


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/patientDashboard",
    element: <PatientDashboard />,
  },
  {
    path: "/patientInformation",
    element: <PatientListdown />,
  },
  {
    path: "/patientInformation/:patientName",
    element: <PatientInformation />,
  },
  {
    path: "/patientInformation/:patientName/createSession",
    element: <CreateSession />,
  },
  {
    path: "./sessionDetails:id",
    element: <SessionDetails />,
  },
  {
    path: "/patientInformation/:patientName/createSession/sessionDetails/:id",
    element: <SessionDetails />,
  },
])
function App() {
  useEffect(() => {
    Aos.init({ duration: 700 });
  })
  return (

    <RouterProvider router={router} />
  );
}

export default App;

// {/* <HashRouter> */}
    // </HashRouter>
// <div className="App">
//     <Home />
// </div > 